import { getAuth, signInWithCustomToken } from "firebase/auth"
import { useRouter } from "next/router"
import { useEffect } from "react"

const useTokenFromUrl = () => {
  const router = useRouter()
  const { token } = router.query
  return token
}

export default function TokenAuthenticator() {
  const token = useTokenFromUrl()

  useEffect(() => {
    const signIn = async () => {
      try {
        await signInWithCustomToken(getAuth(), token as string)
      } catch (error) {
        console.error("Error signing in with custom token", error)
      }
    }

    if (token) {
      signIn()
    }
  }, [token])

  return <></>
}
