import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import dynamic from "next/dynamic"
import { useDisableScroll } from "../functions/general"

const ProductGroupModal = dynamic(() => import("components/orderPage/ProductGroupModal/ProductGroupModal"), {
  ssr: false,
})

const ProductGroupModalLoader = () => {
  const { location } = useStorefrontLocation()

  const open = !!location.productGroupId

  useDisableScroll(open || false)

  return open ? <ProductGroupModal /> : null
}

export default ProductGroupModalLoader
