import { Backbutton, CatBreadcrumb } from "components/orderPage/CategorySelectors/styles/CategoriesStyles"
import styled from "styled-components"

interface StickyHeaderWrapperProps {
  sticky?: boolean
}

export const StickyHeaderWrapper = styled(CatBreadcrumb)<StickyHeaderWrapperProps>`
  position: fixed;
  z-index: 100;
  max-width: calc(700px - var(--shopSpace) * 2);
  width: calc(100% - var(--shopSpace) * 2);
  flex-direction: column;
  align-items: stretch;
  min-height: 60px;
  padding: 5px;
  height: auto;
  overflow: visible;
  opacity: 0;
  transform: translateY(-50%);
  transition: all ease 0.5s;
  margin: var(--shopSpace);
  top: env(safe-area-inset-top, 20px);
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: ${props => props.theme.white};
    box-shadow: ${props => props.theme.smallShadow};
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: calc(var(--shopSpace) * -1 - env(safe-area-inset-top, 20px));
    left: calc(var(--shopSpace) * -1);
    right: calc(var(--shopSpace) * -1);
    height: calc(env(safe-area-inset-top, 20px) + 100px);
    pointer-events: none;
    background: linear-gradient(180deg, white, white, rgba(255, 255, 255, 0.001));
  }

  ${props =>
    props.sticky &&
    `
     opacity: 1;
     pointer-events: auto;
     transform: none
  `};
`

export const GridWrapper = styled.div`
  width: calc(100% + 10px);
  margin: 15px 0 0 -5px;
`

export const StickyHeaderInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const StickyHeaderContent = styled.div`
  flex: 1;
  padding: 0 10px;
  font-weight: bold;
  font-size: 1rem;
`

export const ExpandButton = styled(Backbutton)`
  border-radius: 10px;
  padding: 0;
  margin: 0;
  width: 50px;
  z-index: 2;
`
