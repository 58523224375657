import round from "lodash/round"
import React, { useEffect } from "react"
import Shop from "types/firestore/shop"

// Adding line breaks for pargraph texts
export function addLineBreaks(paragraph: string) {
  if (!paragraph) {
    return
  }
  return paragraph.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line} <br />
    </React.Fragment>
  ))
}

// Filter for only the visible objects in an array
export function visibleOnly<T extends { visible?: boolean }>(arrayOfObjects: T[]) {
  if (arrayOfObjects) {
    return arrayOfObjects.filter(elem => elem.visible)
  }
  //TODO: Remove, when function is only used in TypeScript
  console.error(`no array to filter`)
  return []
}

// Parse a price to a good looking string
export function parsePrice(price: number) {
  return `${round(price / 100, 2)
    .toFixed(2)
    .replace(".", ",")} €`
}

export const useDisableScroll = (state: boolean) => {
  const setScrollState = (state: boolean) => {
    const html = document.querySelector("html")
    const body = document.querySelector("html")

    if (body) {
      body.style.overflow = state ? "hidden" : "auto"
      body.style.touchAction = state ? "none" : "auto"
    }
    if (html) {
      html.style.overflow = state ? "hidden" : "auto"
      html.style.touchAction = state ? "none" : "auto"
    }
  }

  useEffect(() => {
    setScrollState(state)
    return () => (state ? setScrollState(false) : undefined)
  }, [state])
}

export const parseOrderInformationText = (shop: Shop | undefined) => {
  if (shop) {
    const snippets: string[] = []
    if (shop.fees?.pickup) {
      snippets.push(`Abholgebühr: ${parsePrice(shop.fees.pickup * 100)}`)
    }

    if (shop.fees?.delivery) {
      snippets.push(`Fixe Liefergebühr: ${parsePrice(shop.fees.delivery * 100)}, `)
    }

    return snippets.join(" | ")
  } else {
    return ""
  }
}
