import { WithRef } from "hooks/firestore/FirestoreDocument"
import { Category } from "types/firestore/category"

// Deep check if products are somewhere down the line of categories from a given one
export const categoryIsParentingProducts = (category: WithRef<Category>, categories: WithRef<Category>[]): boolean => {
  if (!category.visible) {
    return false
  }

  if (category.productGroups?.length || category.bundles?.length) {
    return true
  }
  if (category.subCategories?.length) {
    return categories
      .filter(subCategory => category.subCategories?.includes(subCategory._ref.id))
      .some(subCategory => categoryIsParentingProducts(subCategory, categories))
  }
  return false
}
