import { useCategories } from "hooks/firestore/simple/useCategories"
import { memo } from "react"

const NoticeIfEmptyShop = memo(() => {
  const categories = useCategories("")?.filter(c => c.visible && c.parentVisible)
  if (categories === undefined) return null
  if (categories.length > 0) return null
  return (
    <>
      <style jsx>{`
        h1 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
          margin: 0;
        }
        div {
          background: var(--white);
          padding: 1rem;
          border-radius: 15px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 0 3rem 10rem 3rem;
        }
      `}</style>
      <div>
        <h1>Zur Zeit sind hier keine Produkte im Verkauf</h1>
        <p>Bitte komm zu einem späteren Zeitpunkt noch einmal wieder</p>
      </div>
    </>
  )
})
NoticeIfEmptyShop.displayName = "NoticeIfEmptyShop"
export default NoticeIfEmptyShop
