import styled from "styled-components"

export const CatWrapper = styled.section`
  margin: 0 0 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;

  h2 {
    color: var(--white);
  }
`

interface CatGridWrapperProps {
  slide: boolean
}

export const CatGridWrapper = styled.section<CatGridWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 0.5rem);
  margin-left: -0.25rem;
  padding: 0.5rem var(--shopSpace);
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  ${props =>
    props.slide &&
    `
    margin-top: 15;
    flex-wrap: nowrap;
    overflow: scroll;
    padding: 0;
 `};
`

interface GridItemProps {
  small: boolean
}

export const GridItem = styled.button<GridItemProps>`
  flex: 0 0 calc(100% / 2);
  padding: 0.25rem;
  border: none;
  background: none;
  transition: all 0.1s ease;
  :focus {
    outline: none;
  }

  ${props =>
    props.small &&
    `
    flex: 0 0 calc(100% / 2.5);
    
    :first-child {
      margin-left: var(--shopSpace);
    }
  
  `};

  @media (orientation: landscape) {
    flex: 0 0 calc(100% / 3);

    ${props =>
      props.small &&
      `
    flex: 0 0 calc(100% / 3.8);
  
  `};
  }
`

export const GridItemInner = styled.div`
  width: 100%;
  padding-top: 70%;
  position: relative;
  margin: 0;
`

export const GridItemContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 5px;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-end;
  //box-shadow: ${props => props.theme.smallShadow};
  background: ${props => props.theme.white};
  //border: 2px solid ${props => props.theme.white};
  color: black;
  overflow: hidden;

  @media (orientation: landscape) {
    padding: 10px;
  }
`

interface GridEmojiProps {
  bgIcon: boolean
}

export const GridEmoji = styled.p<GridEmojiProps>`
  z-index: 2;
  position: relative;
  font-size: 9vw;
  margin-bottom: 0;
  margin-top: -5px;
  flex: 1;
  white-space: nowrap;

  ${props =>
    props.bgIcon &&
    `
      font-size: 20vw;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.4;
      transform: translate(15%, -15%);
  `};

  @media (orientation: landscape) {
    font-size: 50px;

    ${props =>
      props.bgIcon &&
      `
      font-size: 80px
  `};
  }
`

interface GridHeadlineProps {
  small: boolean
}

export const GridHeadline = styled.p<GridHeadlineProps>`
  z-index: 2;
  position: relative;
  font-size: 1.2rem;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 600;
  hyphens: auto;
  max-width: 100%;
  white-space: normal;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;

  ${props =>
    props.small &&
    `
      font-size: 1rem;
  `};

  @media (orientation: landscape) {
    font-size: 1rem;
  }
`

export const GridImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const GridImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const CatBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - var(--shopSpace) * 2);
  margin-left: var(--shopSpace);
  height: 60px;
  padding: 0 5px 0;
  background: ${props => props.theme.white};
  //box-shadow: ${props => props.theme.smallShadow};
  border-radius: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  z-index: 1;
`

export const ResetButton = styled.button`
  appearance: none;
  border: none;
  margin: 0;
  outline: none;
  position: relative;
  z-index: 2;
  padding: 0;
  background: var(--gradient);
  color: var(--gradientText);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  :focus {
    outline: none;
  }
`
export const Backbutton = styled(ResetButton)`
  z-index: 1;
  background: linear-gradient(90deg, #111, #333);
  margin-left: -15px;
  padding-left: 10px;
  width: 60px;
  color: white;
`

export const MoreButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

  :focus {
    outline: none;
  }

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0.1;
  }

  svg {
    position: relative;
    z-index: 2;
    background-color: var(--lighter-gray);
  }
`

interface BreadcrumbItemProps {
  active: boolean
}

export const BreadcrumbItem = styled.button<BreadcrumbItemProps>`
  appearance: none;
  border: none;
  background: none;
  font-size: 1rem;
  white-space: nowrap;
  padding: 0;
  display: flex;
  align-items: center;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dark);

  :focus {
    outline: none;
  }

  ${props =>
    props.active &&
    `
    pointer-events: none;
    font-weight: 600;
    flex: 1;
  `};

  @media (orientation: landscape) {
    font-size: 16px;
  }
`
