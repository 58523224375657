import { ExpandLess, ExpandMore } from "components/LEGACY/styled-components/Icons"
import CategoryGridItem from "components/orderPage/CategorySelectors/CategoryGridItem"
import { categoryIsParentingProducts } from "components/orderPage/functions/categories"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useCategories } from "hooks/firestore/simple/useCategories"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import React, { RefObject, useRef, useState } from "react"
import { Category } from "types/firestore/category"
import { CatGridWrapper, MoreButton } from "./styles/CategoriesStyles"

interface CategoryGridProps {
  expandable?: boolean
  initiallyExpanded?: boolean
}

const CategoryGrid = ({ expandable, initiallyExpanded }: CategoryGridProps) => {
  const categories = useCategories()
  const { location, setLocation } = useStorefrontLocation()

  const isB2BStorefront = useB2BStorefront()
  const isAdminStorefront = useAdminStorefront()

  const parentId = location.categoryIds?.[location.categoryIds.length - 1] || ""
  const cats = categories
    ?.filter(category => parentId === category.parent && categoryIsParentingProducts(category, categories))
    .filter(category => isAdminStorefront || isB2BStorefront || !category.isB2BOnly)
    .sort((a, b) => a.position - b.position)

  const wrapperRef: RefObject<HTMLElement> = useRef<HTMLElement>(null)

  const [expanded, setExpanded] = useState(initiallyExpanded)

  const useSlider = !expanded

  const handleItemClick = (cat: WithRef<Category>) => {
    setExpanded(initiallyExpanded)
    setLocation({ categoryIds: [...(location.categoryIds || []), cat._ref.id] })
  }
  return cats && cats.length > 1 ? (
    <>
      <CatGridWrapper slide={useSlider} ref={wrapperRef}>
        {cats.slice(0, expanded || useSlider ? cats.length : 4).map(cat => (
          <CategoryGridItem key={cat._ref.id} cat={cat} small={useSlider} onClick={() => handleItemClick(cat)} />
        ))}
        {useSlider && <div style={{ flex: "0 0 10px" }} />}
      </CatGridWrapper>
      {expandable && cats.length > 4 && (
        <MoreButton
          onClick={() => {
            // e.currentTarget.scrollIntoView({ block: "nearest", inline: "nearest" })
            setExpanded(!expanded)
          }}
        >
          {expanded ? <ExpandLess color={"currentColor"} size="21" /> : <ExpandMore color={"currentColor"} size="21" />}
        </MoreButton>
      )}
    </>
  ) : null
}

export default React.memo(CategoryGrid)
