import PlaceholderImage from "components/utils/PlaceholderImage"
import React, { ReactNode } from "react"
import { BlurImage } from "types/firestore/productGroup"
import {
  BeforePrice,
  DiscountBadge,
  Price,
  ProductDesc,
  ProductImagePlaceholder,
  ProductImageWrapper,
  ProductItemWrapper,
  ProductMeta,
  ProductMetaWrapper,
  ProductPriceWrapper,
  ProductTitle,
  Unit,
} from "./styles/ProductItemStyles"

interface ProdImgPlaceholderProps {
  loading?: boolean
  small?: boolean
}

const _ProdImgPlaceholder = ({ loading, small = false }: ProdImgPlaceholderProps) => {
  return (
    <ProductImagePlaceholder load={loading} small={small}>
      <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 42.5 42.5">
        <path
          d="M19.1,9.8v29l18-3.3c2.5-0.5,4.3-2.7,4.3-5.2V12.3c0-2.6-1.8-4.7-4.3-5.2L25,4.8c-0.3-0.1-0.6-0.1-0.9-0.1
                C21.3,4.7,19.1,7,19.1,9.8"
        />
        <path
          d="M16.3,3.7L4.5,8.3c-2,0.8-3.4,2.7-3.4,4.9v16.1c0,2.2,1.3,4.1,3.4,4.9l6.7,2.6c1.9,0.7,4.1-0.2,4.8-2.2
                c0.2-0.4,0.2-0.9,0.2-1.3V3.7z"
        />
      </svg>
    </ProductImagePlaceholder>
  )
}

export const ProdImgPlaceholder = React.memo(_ProdImgPlaceholder)

interface ProductItemProps {
  image?: BlurImage
  title?: string
  desc: string | ReactNode
  beforePrice?: number | string
  price?: number | string
  unit?: string
  onClick?: () => void
  descOverflow?: boolean
  soldOut?: boolean
  loading?: boolean
  discountRate?: number
  inventoryNotice?: string
  basePrice?: string
}

const ProductTile = ({
  image,
  title,
  desc,
  beforePrice,
  price,
  unit,
  onClick,
  descOverflow = true,
  soldOut,
  loading,
  discountRate,
  inventoryNotice = "",
  basePrice,
}: ProductItemProps) => {
  return (
    <ProductItemWrapper onClick={onClick || (() => undefined)} disabled={soldOut || loading} load={loading}>
      {image ? (
        <ProductImageWrapper>
          <PlaceholderImage
            src={image}
            alt="Produktbild"
            layout="fixed"
            width={70}
            height={70}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </ProductImageWrapper>
      ) : (
        <ProdImgPlaceholder loading={loading} />
      )}
      {!loading && (
        <ProductMetaWrapper>
          <ProductMeta>
            {!loading && inventoryNotice ? <ProductDesc oneLine={descOverflow}>{inventoryNotice}</ProductDesc> : null}
            <ProductTitle>{title}</ProductTitle>

            <ProductDesc oneLine={descOverflow}>{desc}</ProductDesc>
          </ProductMeta>
          {(price || price === 0) && unit && (
            <ProductPrice
              beforePrice={beforePrice}
              price={price}
              unit={unit}
              discountRate={discountRate}
              withUnit={unit !== "Stück" && unit !== "piece"}
              basePrice={basePrice}
            />
          )}
        </ProductMetaWrapper>
      )}
    </ProductItemWrapper>
  )
}

interface ProductPriceProps {
  beforePrice?: number | string
  price: number | string
  unit?: string
  currency?: "€"
  withUnit?: boolean
  discountRate?: number
  basePrice?: string
}

const _ProductPrice = ({
  beforePrice,
  price,
  unit,
  currency = "€",
  withUnit = true,
  discountRate,
  basePrice,
}: ProductPriceProps) => {
  const hasStrikePrice = !!discountRate
  return (
    <ProductPriceWrapper>
      <Price>
        {beforePrice && <BeforePrice>{beforePrice}</BeforePrice>}
        {price} {currency}
      </Price>
      {withUnit && <Unit>{unit}</Unit>}
      {hasStrikePrice && <DiscountBadge>- {discountRate}%</DiscountBadge>}
      {basePrice && <BeforePrice>{basePrice}</BeforePrice>}
    </ProductPriceWrapper>
  )
}

export const ProductPrice = React.memo(_ProductPrice)

export default React.memo(ProductTile)
