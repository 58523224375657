//import OpenSearch from "components/orderPage/Search/OpenSearch"
import SearchBox from "components/orderPage/Search/SearchBox"
import dynamic from "next/dynamic"
import { useState } from "react"

const OpenSearch = dynamic(() => import("components/orderPage/Search/OpenSearch"), { ssr: false })

interface ConnectedSearchProps {
  open: boolean
  setSearchOpen: (open: boolean) => void
}

const ConnectedSearch = ({ setSearchOpen, open }: ConnectedSearchProps) => {
  const [query, setQuery] = useState("")

  return (
    <>
      <SearchBox open={open} setSearchOpen={setSearchOpen} setQuery={setQuery} />
      {open ? <OpenSearch query={query} closeSearch={() => setSearchOpen(false)} /> : null}
    </>
  )
}

export default ConnectedSearch
