import { useEffect, useRef, useState } from "react"

function useInView(
  options: IntersectionObserverInit = {
    root: null,
    threshold: 0.1,
    rootMargin: "200px",
  }
) {
  const [inView, setInView] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return

    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setInView(entry.isIntersecting)
    }, options)

    const currentElement = ref.current
    observer.observe(currentElement)

    return () => {
      observer.unobserve(currentElement)
      observer.disconnect()
    }
  }, [ref.current, options])

  return { ref, inView }
}

export default useInView
