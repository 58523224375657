import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { useDisableScroll } from "../functions/general"
import ConnectedSearch from "./ConnectedSearch"
import styles from "./Search.module.scss"

function Search() {
  const [open, setOpen] = useState(false)

  const searchPlace = useRef<HTMLDivElement>(null)
  const [searchPos, setSearchPos] = useState<undefined | { top: number; left: number; width: number; height: number }>()
  const [vHeight, setVHeight] = useState((typeof window !== "undefined" && window?.visualViewport?.height) || 0)

  useDisableScroll(open)

  useEffect(() => {
    const setSearchInput = () => {
      const scrollT = window.scrollY
      const el = searchPlace.current
      const rect = el?.getBoundingClientRect()
      setSearchPos(
        rect
          ? {
              top: rect.top + scrollT,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            }
          : undefined
      )
      setVHeight(window?.visualViewport?.height || 0)
      document.documentElement.style.setProperty("--viewHeight", (window?.visualViewport?.height || 0) + "px")
    }
    setSearchInput()

    window.addEventListener("DOMContentLoaded", setSearchInput)
    window.addEventListener("load", setSearchInput)
    window.addEventListener("scroll", setSearchInput)
    window.addEventListener("resize", setSearchInput)
  }, [])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 150)
    }
  }, [open])

  return (
    <>
      <div className={`${styles.shopSearchPlace}`} ref={searchPlace} />
      {typeof document !== "undefined"
        ? ReactDOM.createPortal(
            <div
              className={`${styles.shopSearch} ${open ? styles.open : ""}`}
              style={open ? { top: 0, left: 0, paddingBottom: vHeight ? window.innerHeight - vHeight : 0 } : searchPos}
            >
              <ConnectedSearch open={open} setSearchOpen={setOpen}></ConnectedSearch>
            </div>,
            document.body
          )
        : null}
    </>
  )
}

export default React.memo(Search)
