import { useActiveDistributionOptions } from "hooks/firestore/advanced/useActiveDistributionOptions"

export function useShopStatus() {
  const distributionOptions = useActiveDistributionOptions()

  const shopIsOpen = !!distributionOptions?.length
  const allowLowOrder = true
  const minOrderValue = 0

  const deliveryOffered = !!distributionOptions?.find(e => e.type === "delivery")

  const pickupOffered = !!distributionOptions?.find(e => e.type === "pickup")

  const shippingOffered = !!distributionOptions?.find(e => e.type === "shipping")

  const orderingPossible = true
  const orderingActive = true

  const checkoutActive = shopIsOpen

  return {
    allowLowOrder,
    deliveryOffered,
    minOrderValue,
    pickupOffered,
    shippingOffered,
    shopIsOpen,
    orderingPossible,
    orderingActive,
    checkoutActive,
  }
}
