import { where } from "firebase/firestore"
import useQuery from "hooks/firestore/core/useQuery"
import { getDistributionOptionsRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"

export function useActiveDistributionOptions() {
  const shopId = useShopId()
  const { documents } = useQuery(getDistributionOptionsRef(shopId), where("active", "==", true))
  return documents
}
