import { useDocument } from "hooks/firestore/core/useDocument"
import { getBasketRef } from "hooks/firestore/getRefs"
import { useShopId } from "hooks/localstate/context/useShopId"
import { unsetBasketId, useBasketId } from "hooks/localstate/localstorage/useBasketId"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"
import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react"

interface BasketOpenContextType {
  basketOpen: boolean
  setBasketOpen: Dispatch<SetStateAction<boolean>>
}

export const BasketOpenContext = createContext<BasketOpenContextType>({
  basketOpen: false,
  setBasketOpen: () => undefined,
})

type BasketOpenContextProviderProps = {
  children?: ReactNode
}

export const BasketOpenContextProvider = ({ children }: BasketOpenContextProviderProps) => {
  const [basketOpen, setBasketOpen] = useState(false)
  const shopId = useShopId()
  const internal = useAdminStorefront()
  const basketId = useBasketId()
  const { document: basket, loading } = useDocument(getBasketRef(shopId, basketId))
  useEffect(() => {
    if (shopId && basketId && (basket?.status === "ordered" || (!loading && !basket))) {
      unsetBasketId(shopId, internal)
    }
  }, [shopId, !!basket, loading])

  return <BasketOpenContext.Provider value={{ basketOpen, setBasketOpen }}>{children}</BasketOpenContext.Provider>
}
