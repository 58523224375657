import { orderBy, where } from "firebase/firestore"
import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { useStorefrontId } from "hooks/firestore/advanced/useStorefrontId"
import { getQuery, useQuery } from "hooks/firestore/core/useQuery"
import { getProductGroupsRef } from "hooks/firestore/getRefs"
import { useMemo } from "react"

export function useSpecialOffers(hideInvisible?: boolean) {
  const offeringId = useOfferingId()
  const storefrontId = useStorefrontId()
  const { documents } = useQuery(
    getProductGroupsRef(offeringId, storefrontId),
    where("specialOffer.active", "==", true),
    orderBy("specialOffer.from", "desc")
  )
  const filteredSpecialOffers = useMemo(
    () => (hideInvisible ? documents?.filter(doc => doc.visible && doc.categoryTreeVisible) : documents),
    [documents, !!hideInvisible]
  )
  return filteredSpecialOffers
}

export async function getSpecialOffers(offeringId: string, storefrontId: string, hideInvisible?: boolean) {
  const documents = await getQuery(
    getProductGroupsRef(offeringId, storefrontId),
    where("specialOffer.active", "==", true),
    orderBy("specialOffer.from", "desc")
  )
  const filteredSpecialOffers = hideInvisible
    ? documents?.filter(doc => doc.visible && doc.categoryTreeVisible)
    : documents
  return filteredSpecialOffers
}
