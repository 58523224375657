import { orderBy, where } from "firebase/firestore"
import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { useStorefrontId } from "hooks/firestore/advanced/useStorefrontId"
import { useQuery } from "hooks/firestore/core/useQuery"
import { getProductGroupsRef } from "hooks/firestore/getRefs"

export function useCategoryVisibleProductGroups(categoryId?: string) {
  const offeringId = useOfferingId()
  const storefrontId = useStorefrontId()
  const { documents } = useQuery(
    typeof categoryId === "string" ? getProductGroupsRef(offeringId, storefrontId) : undefined,
    where("category", "==", categoryId),
    where("visible", "==", true),
    orderBy("position", "asc")
  )
  return documents
}
