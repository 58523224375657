import { useRouter } from "next/router"
import { useMemo } from "react"

interface TreeParameters {
  categoryIds?: string[]
  productGroupId?: string
  itemId?: string
  bundleId?: string
}

/**
 * This should handle the weird URL parameters that occur sometimes.
 *
 * Example: ?c=%2Fkl0q001t&i=jq1urYlUJQccyeM8mDtx&p=%2Fkl0q001t%2Fkl0q001t%2Fkl2355bj%2Fkl2355bj%2Fkl236f53
 *
 * They do not appear to be set by us, as we previously sanitized all strings in setLocation calls.
 */
const sanitizeStorefrontLocationParameter = (value: string | undefined) => {
  const firstPathSegment = value?.split("/").find(part => part)
  return firstPathSegment
}

export function useStorefrontLocation() {
  const router = useRouter()

  const searchParams = useMemo(() => {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search)
    } else return new URLSearchParams()
  }, [router.query])

  const params: TreeParameters = useMemo(() => {
    const categoryIds = searchParams
      .getAll("c")
      .map(sanitizeStorefrontLocationParameter)
      .flatMap(c => (c ? [c] : []))
    const productGroupId = sanitizeStorefrontLocationParameter(searchParams.get("p") || undefined)
    const itemId = sanitizeStorefrontLocationParameter(searchParams.get("i") || undefined)
    const bundleId = sanitizeStorefrontLocationParameter(searchParams.get("b") || undefined)
    return { categoryIds, productGroupId, itemId, bundleId }
  }, [searchParams])

  const setLocation = (newParams?: TreeParameters, replace?: boolean) => {
    const newSearchParams = new URLSearchParams(window.location.search)
    newSearchParams.delete("c")
    newSearchParams.delete("p")
    newSearchParams.delete("i")
    newSearchParams.delete("b")

    if (newParams) {
      const sanitizedProductGroupId = sanitizeStorefrontLocationParameter(newParams.productGroupId)
      const sanitizedItemId = sanitizeStorefrontLocationParameter(newParams.itemId)
      const sanitizedCategoryIds = newParams.categoryIds
        ? newParams.categoryIds.map(sanitizeStorefrontLocationParameter).flatMap(c => (c ? [c] : []))
        : []
      const sanitizedBundleId = sanitizeStorefrontLocationParameter(newParams.bundleId)

      sanitizedCategoryIds.forEach(categoryId => {
        newSearchParams.append("c", categoryId)
      })
      sanitizedItemId && newSearchParams.append("i", sanitizedItemId)
      sanitizedProductGroupId && newSearchParams.append("p", sanitizedProductGroupId)
      sanitizedBundleId && newSearchParams.append("b", sanitizedBundleId)
    }

    const searchString = newSearchParams.toString()

    router[replace ? "replace" : "push"]?.(
      {
        pathname: window.location.pathname,
        search: searchString && "?" + searchString,
      },
      undefined,
      { shallow: true }
    )
  }
  return { location: params, setLocation }
}
