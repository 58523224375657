import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import dynamic from "next/dynamic"
import { useDisableScroll } from "../functions/general"

const BundleModal = dynamic(() => import("components/orderPage/BundleModal/BundleModal"), {
  ssr: false,
})

const BundleModalLoader = () => {
  const { location } = useStorefrontLocation()

  const open = !!location.bundleId

  useDisableScroll(open || false)

  return open ? <BundleModal /> : null
}

export default BundleModalLoader
