import { CenterWidthContainer, StorefrontPageContainer } from "components/LEGACY/styled-components/Storefront"
import SiteFooter from "components/header-footer/SideFooter"
import BundleModalLoader from "components/orderPage/BundleModal"
import CategoryDisplay from "components/orderPage/CategoryDisplay"
import CategorySelectors from "components/orderPage/CategorySelectors"
import CategoryBreadcrumb from "components/orderPage/CategorySelectors/CategoryBreadcrumb"
import FeaturedProducts from "components/orderPage/FeaturedProducts"
import NoticeIfEmptyShop from "components/orderPage/NoticeIfEmptyShop"
import ProductGroupModalLoader from "components/orderPage/ProductGroupModal"
import BasketLoader from "components/orderPage/StickyBasket/BasketLoader"
import StickyHeader from "components/orderPage/StickyHeader"
import TaxNote from "components/orderPage/TaxNote"
import TokenAuthenticator from "components/orderPage/TokenAuthenticator"
import { BasketOpenContextProvider } from "context/orderFormContexts/BasketOpenContext"
import React from "react"
import ShopHeader from "./ShopHeader"

const MainView = () => {
  return (
    <>
      <style jsx>{`
        main {
          position: relative;
        }
      `}</style>
      <BasketOpenContextProvider>
        <StorefrontPageContainer>
          <CenterWidthContainer>
            <ShopHeader />
            <StickyHeader />
            <main>
              <TaxNote />
              <NoticeIfEmptyShop />
              <FeaturedProducts />
              <CategoryBreadcrumb />
              <CategorySelectors />
              <CategoryDisplay />
            </main>
          </CenterWidthContainer>
          <SiteFooter />

          <ProductGroupModalLoader />
          <BundleModalLoader />
          <BasketLoader />
          <TokenAuthenticator />
        </StorefrontPageContainer>
      </BasketOpenContextProvider>
    </>
  )
}

export default React.memo(MainView)
