import { useBasket } from "hooks/firestore/simple/useBasket"
import { useEvaluateChannelAttribution } from "hooks/localstate/url/useEvaluateChannelAttribution"
import dynamic from "next/dynamic"

const StickyBasket = dynamic(() => import("components/orderPage/StickyBasket/StickyBasket"), {
  ssr: false,
})

const BasketLoader = () => {
  const basket = useBasket()
  const open = basket && basket?.status !== "ordered" && (basket.items?.length || basket.bundles?.length)
  useEvaluateChannelAttribution()

  return open ? <StickyBasket /> : null
}

export default BasketLoader
