import { ChevronLeft, Search } from "components/LEGACY/styled-components/Icons"
import debounce from "lodash/debounce"
import React, { ChangeEvent } from "react"
import styles from "./SearchBox.module.scss"

interface SearchBoxProps {
  open: boolean
  setSearchOpen: (open: boolean) => void
  setQuery: (query: string) => void
}

const SearchBox = ({ open, setSearchOpen, setQuery }: SearchBoxProps) => {
  const debouncedSearch = debounce(query => {
    setQuery(query)
  }, 100)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    debouncedSearch(e.target.value)
  }

  return (
    <div className={`${styles.inputWrapper} ${open ? styles.open : ""}`}>
      <button
        className={`${styles.closeButton}`}
        onClick={() => {
          setSearchOpen(false)
        }}
      >
        <ChevronLeft size={25} color={"currentColor"} />
      </button>
      <span className={`${styles.searchIcon}`}>
        <Search size={21} color={"currentColor"} />
      </span>
      <input
        className={`${styles.searchInput}`}
        type="search"
        onChange={onChange}
        onClick={() => {
          setSearchOpen(true)
        }}
        placeholder={"Suche..."}
        autoComplete={"off"}
      />
    </div>
  )
}

export default React.memo(SearchBox)
