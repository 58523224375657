import { useEffect, useState } from "react"

/** If the offset is below this value, it is not set, because it is negligible */
const MINIMUM_OFFSET = 5000

let globalOffset = 0
let offsetPromise: undefined | Promise<void> = undefined

async function setGlobalOffset() {
  if (!offsetPromise) {
    const fetchOffset = async () => {
      //getDate() is not appropriate here, because it already depends on the offsetPromise
      const currentDate = Date.now()

      //For local testing
      // const time = await fetch("http://worldtimeapi.org/api/timezone/Europe/Berlin")
      // const result = await time.json()
      // if (result?.unixtime === undefined) {
      //   throw new Error("Failed to get valid time")
      // }
      // const newOffset = result?.unixtime * 1000 - currentDate.getTime()

      //For production
      const time = await fetch("/api/time")
      const result = (await time.json()) as { time: number } | undefined
      if (result?.time === undefined) {
        throw new Error("Failed to get valid time")
      }
      const newOffset = result.time - currentDate

      if (Math.abs(newOffset) > MINIMUM_OFFSET) {
        globalOffset = newOffset
      }
      console.log(
        `Setting time offset to ${Math.floor(globalOffset / 1000)}.${Math.floor(Math.abs(globalOffset) % 1000)} seconds`
      )
    }

    offsetPromise = fetchOffset()
  }

  // TODO: Maybe try again after a few minutes, if the promise got rejected
  await offsetPromise.catch()
}

/** Use the offset by which the system time is wrong */
export function useOffset() {
  const [offset, setOffset] = useState(globalOffset)
  useEffect(() => {
    const work = async () => {
      await setGlobalOffset()
      if (offset !== globalOffset) {
        setOffset(globalOffset)
      }
    }
    work()
  }, [])

  return offset
}

/** Get the offset by which the system time is wrong.
 *  Returns 0 if no offset was fetched yet.
 *  To start fetching an offset call useOffset or getOffsetAsync.
 **/
export function getOffset() {
  return globalOffset
}

/** Get the offset by which the system time is wrong. */
export async function getOffsetAsync() {
  await setGlobalOffset()
  return globalOffset
}
