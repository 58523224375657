import { ChevronLeft, ChevronRight, Home } from "components/LEGACY/styled-components/Icons"
import { useCategory } from "hooks/firestore/simple/useCategory"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import last from "lodash/last"
import React from "react"
import { Backbutton, BreadcrumbItem, CatBreadcrumb, ResetButton } from "./styles/CategoriesStyles"

const CategoryBreadcrumb = () => {
  const { location, setLocation } = useStorefrontLocation()
  const categoryId = last(location.categoryIds)
  const category = useCategory(categoryId)

  return category ? (
    <CatBreadcrumb>
      <ResetButton onClick={() => setLocation()}>
        <Home size={"16px"} color={"currentColor"} />
      </ResetButton>
      {(location.categoryIds?.length || 0) > 1 && (
        <Backbutton onClick={() => setLocation({ categoryIds: location.categoryIds?.slice(0, -1) })}>
          <ChevronLeft color={"currentColor"} size={"21px"} />
        </Backbutton>
      )}

      <BreadcrumbItem key={category._ref.id} onClick={() => setLocation({ categoryIds: location.categoryIds })} active>
        <ChevronRight color={"black"} size={"21px"} style={{ opacity: 0.3 }} />
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{category.name}</div>
      </BreadcrumbItem>
    </CatBreadcrumb>
  ) : null
}

export default React.memo(CategoryBreadcrumb)
