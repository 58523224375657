import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { useStorefrontId } from "hooks/firestore/advanced/useStorefrontId"
import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getCategoryRef } from "hooks/firestore/getRefs"
import { Category } from "types/firestore/category"

// ts-prune-ignore-next
export function useCategory(categoryId: undefined): undefined
export function useCategory(categoryId: string | undefined): WithRef<Category> | undefined
export function useCategory(categoryId: string | undefined, storefrontId: string): WithRef<Category> | undefined
export function useCategory(
  categoryId: string | undefined,
  storefrontId: string,
  offeringId: string
): WithRef<Category> | undefined
export function useCategory(categoryId: string | undefined, storefrontId?: string, offeringId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realOfferingId = offeringId || useOfferingId()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realStorefrontId = storefrontId || useStorefrontId()
  const { document } = useDocument(getCategoryRef(realOfferingId, realStorefrontId, categoryId))
  return document
}

// ts-prune-ignore-next
export async function getCategory(categoryId: string, storefrontId: string, offeringId: string) {
  return await getDocument(getCategoryRef(offeringId, storefrontId, categoryId))
}
