import { getOffset, getOffsetAsync, useOffset } from "hooks/misc/useOffset"
import { useEffect, useMemo, useState } from "react"

/** Use a correct date.
 *  On the first call this will only return the system time.
 *  Triggers rerenders every every milliseconds. */
// ts-prune-ignore-next
export function useDate(every: number) {
  const offset = useOffset()
  const [time, setTime] = useState<number>(Date.now() + offset)

  useEffect(() => {
    //Start at a "round" time (full second/ minute)
    const untilNext = every - ((Date.now() + offset) % every)
    const ref = {
      timeout: setTimeout(() => {
        setTime(Date.now() + offset)
        ref.timeout = setInterval(() => {
          setTime(Date.now() + offset)
        }, every)
      }, untilNext),
    }

    return () => {
      try {
        clearTimeout(ref.timeout)
      } catch (e) {
        // empty
      }
      try {
        clearInterval(ref.timeout)
      } catch (e) {
        // empty
      }
    }
  }, [every, offset])

  const date = useMemo(() => new Date(time), [time])

  return date
}

/** Get a correct date.
 *  Returns the system date, if no offset was fetched yet.
 *  To start fetching an offset call useDate, useOffset, getDateAsync or getOffsetAsync. */
// ts-prune-ignore-next
export function getDate() {
  const offset = getOffset()
  return new Date(Date.now() + offset)
}

/** Get a correct date. */
// ts-prune-ignore-next
export async function getDateAsync() {
  const offset = await getOffsetAsync()
  return new Date(Date.now() + offset)
}
