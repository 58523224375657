import styled, { css } from "styled-components"

interface BundleCardWrapperProps {
  disabled?: boolean
  load?: boolean
}

export const BundleCardWrapper = styled.button<BundleCardWrapperProps>`
  background-color: ${props => props.theme.white};
  text-align: left;
  width: 100%;
  display: flex;
  align-items: stretch;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  border-radius: 20px;
  border: none;
  color: var(--dark);

  position: relative;

  ${props =>
    props.disabled &&
    !props.load &&
    css`
      opacity: 0.7;
    `}

  ${props =>
    props.load &&
    css`
      animation: glowing 2500ms infinite;
    `}
  @keyframes glowing {
    0% {
      background-color: ${props => props.theme.offwhite};
    }
    50% {
      background-color: ${props => props.theme.grey};
    }
    100% {
      background-color: ${props => props.theme.offwhite};
    }
  }
`

export const BundleIconWrapper = styled.div`
  margin-right: 0.5rem;
  border-radius: calc(20px - 0.3rem);
  border: none;
  overflow: hidden;
  height: 70px;
  width: 70px;
`

export const BundleIcon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid var(--navBG);
  background: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BundleMetaWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const BundleMeta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

export const BundleTitle = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.2;
  font-weight: 500 !important;
  color: currentColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface BundleDescProps {
  oneLine?: boolean
}

export const BundleDesc = styled.p<BundleDescProps>`
  width: 100%;
  font-size: 0.8rem;
  opacity: 0.5;
  margin: 0;

  ${props =>
    props.oneLine
      ? css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;

          br {
            display: none;
          }
        `
      : ""};
`

export const BundlePriceWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  margin: 0;
  padding: 0 0.5rem;
  line-height: 1.3;
  position: relative;
  /* background: green; */
`
export const DiscountBadge = styled.div`
  position: absolute;
  top: -0.75rem;
  right: 0.1rem;
  /* opacity: 0.8; */
  background: var(--gradient);
  border-radius: 5px;
  box-shadow: var(--boxShadow);
  color: var(--white);
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.1rem 0.25rem;
  overflow: visible;

  -webkit-transform: rotate(12deg);
  -moz-transform: rotate(12deg);
  -ms-transform: rotate(12deg);
  -o-transform: rotate(12deg);
  transform: rotate(12deg);
`

export const BeforePrice = styled.span`
  font-size: 0.7rem;
  opacity: 0.5;
  padding-bottom: 0;
  margin-right: 0.2rem;
  text-transform: uppercase;
`

export const Price = styled.span`
  font-size: 1.2rem;
`

export const Unit = styled(BeforePrice)`
  display: flex;
  align-items: flex-end;
  margin-right: 0;

  &::before {
    content: "";
    width: 1px;
    height: 0.7rem;
    background: currentColor;
    transform: translateY(-3px) rotate(25deg);
    font-size: 1.2rem;
    margin: 0 3px 0 7px;
  }
`
