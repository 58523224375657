import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getPrivateUserRef } from "hooks/firestore/getRefs"
import { useUserId } from "hooks/localstate/auth/useUserId"
import PrivateUser from "types/firestore/privateUser"

// ts-prune-ignore-next
export function usePrivateUser(): WithRef<PrivateUser> | undefined
export function usePrivateUser(userId: string): WithRef<PrivateUser> | undefined
export function usePrivateUser(userId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realUserId = userId || useUserId()
  const { document } = useDocument(getPrivateUserRef(realUserId))
  return document
}

// ts-prune-ignore-next
export async function getPrivateUser(userId: string) {
  return await getDocument(getPrivateUserRef(userId))
}
