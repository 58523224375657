import { ShoppingBasket } from "components/LEGACY/styled-components/Icons"
import { useShopStatus } from "hooks/firestore/advanced/useShopStatus"
import { useBasket } from "hooks/firestore/simple/useBasket"
import { useBasketOpen } from "hooks/localstate/context/useBasketOpen"
import { useEffect, useState } from "react"
import { parsePrice } from "../functions/general"
//TODO: Split StickyBasket.module.scss into multiple modules
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StickyBasket.module.scss"

interface BasketButtonProps {
  onClick?: () => void
  useExpand?: boolean
  white?: boolean
}

//TODO: This is also used outside of StickyBasket
const BasketButton = ({ onClick = () => undefined, useExpand = true, white }: BasketButtonProps) => {
  const basket = useBasket()
  const { checkoutActive } = useShopStatus()
  const price = parsePrice(basket?.total || 0)
  const [changeCart, setChangeCart] = useState(false)
  const { basketOpen, setBasketOpen } = useBasketOpen()

  useEffect(() => {
    setChangeCart(true)

    setTimeout(() => {
      setChangeCart(false)
    }, 300)
  }, [basket?.items, basket?.bundles])

  const disabled =
    ((!basket?.items || basket?.items?.length < 1) && (!basket?.bundles || basket?.bundles?.length < 1)) ||
    !checkoutActive

  return (
    <button
      className={`${styles.cartButton} ${white ? styles.white : ""}`}
      onClick={() => {
        //toggleBasket(true, true)
        setBasketOpen(true)
        onClick()
      }}
      disabled={disabled}
    >
      {basket?.items?.length || basket?.bundles?.length ? (
        <span className={`${styles.countBubble} ${changeCart ? styles.changed : ""}`}>
          {(basket.items?.length || 0) + (basket.bundles?.length || 0)}
        </span>
      ) : null}
      <ShoppingBasket color={"currentColor"} size={21} />
      {useExpand && basketOpen ? <span>{price} - Jetzt Bestellen</span> : <span>{price}</span>}
    </button>
  )
}

export default BasketButton
