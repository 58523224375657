import { ProductPrice } from "components/orderPage/ProductTile"
import PlaceholderImage from "components/utils/PlaceholderImage"
import { useBundle } from "hooks/firestore/simple/useBundle"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import { PackageIcon } from "styled-icons/boxicons-regular"
import {
  BundleCardWrapper,
  BundleDesc,
  BundleIcon,
  BundleIconWrapper,
  BundleMeta,
  BundleMetaWrapper,
  BundleTitle,
} from "./BundleCardStyles"

const BundleCard = ({ bundleId }: { bundleId: string }) => {
  const { location, setLocation } = useStorefrontLocation()
  const bundle = useBundle(bundleId)

  const startBundleModal = () => {
    bundle &&
      setLocation({
        categoryIds: location.categoryIds,
        bundleId: bundle._ref.id,
      })
  }

  if (!bundle) return null

  // check if bundle is visible and available
  if (!bundle.isVisible) return null

  if (bundle.items.length === 0) return null

  return (
    <BundleCardWrapper onClick={startBundleModal}>
      <BundleIconWrapper>
        {bundle.image ? (
          <PlaceholderImage
            src={bundle.image}
            alt="Produktbild"
            layout="fixed"
            width={70}
            height={70}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        ) : (
          <BundleIcon>
            <PackageIcon style={{ width: "32px", height: "32px" }} />
          </BundleIcon>
        )}
      </BundleIconWrapper>

      <BundleMetaWrapper>
        <BundleMeta>
          <BundleTitle>{bundle.name}</BundleTitle>

          {bundle.description && <BundleDesc>{bundle.description}</BundleDesc>}
        </BundleMeta>
        {(bundle.price || bundle.price === 0) && (
          <ProductPrice price={(bundle.price / 100).toFixed(2)} withUnit={false} />
        )}
      </BundleMetaWrapper>
    </BundleCardWrapper>
  )
}

export default BundleCard
