import CategoryGrid from "components/orderPage/CategorySelectors/CategoryGrid"
import { categoryIsParentingProducts } from "components/orderPage/functions/categories"
import { useCategories } from "hooks/firestore/simple/useCategories"
import { useShop } from "hooks/firestore/simple/useShop"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import React from "react"
import { CatWrapper } from "./styles/CategoriesStyles"

function CategorySelectors() {
  const { location } = useStorefrontLocation()
  const shop = useShop()

  const categoriesOnlyView = shop?.categoriesOnlyHomepage && !location.categoryIds?.length

  const categories = useCategories()
  const parentId = location.categoryIds?.[location.categoryIds.length - 1] || ""
  const cats = categories
    ?.filter(category => parentId === category.parent && categoryIsParentingProducts(category, categories))
    .sort((a, b) => a.position - b.position)

  return cats && cats.length > 1 ? (
    <CatWrapper>
      <CategoryGrid expandable initiallyExpanded={categoriesOnlyView} />
    </CatWrapper>
  ) : null
}

export default React.memo(CategorySelectors)
