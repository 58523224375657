import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getBundleRef } from "hooks/firestore/getRefs"
import { Bundle } from "types/firestore/bundle"

// ts-prune-ignore-next
export function useBundle(bundleId: undefined): undefined
export function useBundle(optionId: string | undefined): WithRef<Bundle> | undefined
export function useBundle(bundleId: string | undefined, offeringId: string): WithRef<Bundle> | undefined
export function useBundle(bundleId: string | undefined, offeringId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const offeringIdHook = offeringId || useOfferingId()
  const { document } = useDocument(getBundleRef(offeringIdHook, bundleId))
  return document
}

// ts-prune-ignore-next
export async function getBundle(bundleId: string, offeringId: string) {
  return await getDocument(getBundleRef(offeringId, bundleId))
}
