import ProductGroupCategory from "components/orderPage/CategoryDisplay/ProductGroupCategory"
import { useCategories } from "hooks/firestore/simple/useCategories"
import "intersection-observer" // Polyfill for the react-in-viewport Intersection observer
import React from "react"

interface SubcategoryCategoryProps {
  categoryId: string
}

const SubcategoryCategory = ({ categoryId }: SubcategoryCategoryProps) => {
  const categories = useCategories()

  const sortedCategories = categories?.filter(cat => cat.parent === categoryId).sort((a, b) => a.position - b.position)

  return sortedCategories ? (
    <>
      {sortedCategories.map(cat =>
        !cat.subCategories?.length && (cat.productGroups?.length || cat.bundles?.length) ? (
          <ProductGroupCategory key={cat._ref.id} categoryId={cat._ref.id} />
        ) : (
          <SubcategoryCategory key={cat._ref.id} categoryId={cat._ref.id} />
        )
      )}
    </>
  ) : null
}

export default React.memo(SubcategoryCategory)
