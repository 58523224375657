import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { useStorefrontId } from "hooks/firestore/advanced/useStorefrontId"
import { getCollection, useCollection } from "hooks/firestore/core/useCollection"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getCategoriesRef } from "hooks/firestore/getRefs"
import { Category } from "types/firestore/category"

// ts-prune-ignore-next
export function useCategories(): WithRef<Category>[] | undefined
export function useCategories(storefrontId: string): WithRef<Category>[] | undefined
export function useCategories(storefrontId: string, offeringId: string): WithRef<Category>[] | undefined
export function useCategories(storefrontId?: string, offeringId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realOfferingId = offeringId || useOfferingId()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realStorefrontId = storefrontId || useStorefrontId()
  const { documents } = useCollection<Category>(getCategoriesRef(realOfferingId, realStorefrontId))
  return documents
}

// ts-prune-ignore-next
export async function getCategories(storefrontId: string, offeringId: string) {
  return await getCollection(getCategoriesRef(offeringId, storefrontId))
}
