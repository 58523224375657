import { ChevronLeft, Grid } from "components/LEGACY/styled-components/Icons"
import CategoryGrid from "components/orderPage/CategorySelectors/CategoryGrid"
import { categoryIsParentingProducts } from "components/orderPage/functions/categories"
import { useCategories } from "hooks/firestore/simple/useCategories"
import { useShop } from "hooks/firestore/simple/useShop"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import React, { useEffect, useMemo, useState } from "react"
import AnimateHeight from "react-animate-height"
import {
  ExpandButton,
  GridWrapper,
  StickyHeaderContent,
  StickyHeaderInner,
  StickyHeaderWrapper,
} from "./styles/StickyHeaderStyles"

const StickyHeader = () => {
  const [expand, setExpand] = useState(false)
  const [sticky, setSticky] = useState(false)
  const { location, setLocation } = useStorefrontLocation()
  const categories = useCategories()
  const shop = useShop()

  const parentId = location.categoryIds?.[location.categoryIds.length - 1] || ""
  const cats = useMemo(
    () =>
      categories?.filter(category => parentId === category.parent && categoryIsParentingProducts(category, categories)),
    [categories, parentId]
  )

  const activeCategory = categories?.find(category => category._ref.id === parentId)
  const headerText = activeCategory?.name || shop?.name

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setSticky(true)
      } else {
        setExpand(false)
        setSticky(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const selectCategories = cats?.length

  return (
    <StickyHeaderWrapper sticky={sticky}>
      <StickyHeaderInner>
        {!selectCategories ? (
          <ExpandButton onClick={() => setLocation({ categoryIds: location.categoryIds?.slice(0, -1) })}>
            <ChevronLeft color={"currentColor"} size={25} />
          </ExpandButton>
        ) : (
          <ExpandButton onClick={() => setExpand(!expand)}>
            <Grid color={"currentColor"} size={25} />
          </ExpandButton>
        )}
        <StickyHeaderContent>{headerText}</StickyHeaderContent>
      </StickyHeaderInner>
      {selectCategories ? (
        <AnimateHeight height={expand ? "auto" : 0}>
          <GridWrapper>
            <CategoryGrid />
          </GridWrapper>
        </AnimateHeight>
      ) : null}
    </StickyHeaderWrapper>
  )
}

export default React.memo(StickyHeader)
