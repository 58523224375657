const filterProtocolRegex = /[^0-9a-z]/gi

export function useHost() {
  const host = typeof window !== "undefined" ? window.location.host : "app.getpacked.de"
  const protocol = typeof window !== "undefined" ? window.location.protocol.replace(filterProtocolRegex, "") : "http"
  const origin = `${protocol}://${host}`
  return { host, protocol, origin }
}

export function getHost() {
  const host = typeof window !== "undefined" ? window.location.host : "app.getpacked.de"
  const protocol = typeof window !== "undefined" ? window.location.protocol.replace(filterProtocolRegex, "") : "http"
  const origin = `${protocol}://${host}`
  return { host, protocol, origin }
}
