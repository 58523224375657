import Image, { ImageProps } from "next/image"
import React from "react"
import { BlurImage } from "types/firestore/productGroup"
import styles from "./PlaceholderImage.module.scss"

type PlaceholderImageProps = Omit<
  ImageProps,
  "src" | "placeholder" | "blurDataURL" | "unoptimized" | "alt" | "className"
> & {
  src: BlurImage | string | undefined | null
  className?: string
  alt: string
}

const PlaceholderImage = (props: PlaceholderImageProps) => {
  const { src, className, alt, ...imageProps } = props

  const blurImage = typeof src === "string" ? { url: src } : src
  const placeholderImageProps =
    blurImage?.url && blurImage.url !== "none"
      ? {
          src: blurImage.url,
          ...(blurImage.blur
            ? ({
                placeholder: "blur",
                blurDataURL: `data:image;base64,${blurImage.blur}`,
                className: `${className} ${styles.placeholderImage}`,
              } as const)
            : {}),
        }
      : { src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==", unoptimized: true }

  return <Image className={className} {...imageProps} {...placeholderImageProps} alt={alt} />
}

export default React.memo(PlaceholderImage)
