import { useShop } from "hooks/firestore/simple/useShop"
import dynamic from "next/dynamic"
import { memo, useState } from "react"

const InfoModal = dynamic(() => import("components/orderPage/InfoModal"), { ssr: false })

const TaxNote = memo(() => {
  const [isInfoView, setInfoView] = useState(false)
  const shop = useShop()
  if (shop?.taxNoteTurnedOff) return null
  return (
    <>
      <style jsx>{`
        .taxNote {
          position: absolute;
          top: -1.5rem;
          right: 1rem;
          color: var(--white);
          font-size: 0.85rem;
          line-height: 1;
          opacity: 0.8;
          text-decoration: none;
          cursor: pointer;
        }
      `}</style>
      <a onClick={() => setInfoView(true)} className="taxNote">
        Alle Preise inkl. MwSt., zzgl. Gebühren
      </a>
      {isInfoView ? <InfoModal open={isInfoView} onClose={() => setInfoView(false)} /> : null}
    </>
  )
})
TaxNote.displayName = "NoticeIfEmptyShop"
export default TaxNote
